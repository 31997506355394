.header {
  display: flex;
  align-items: center;
  background-image: linear-gradient(rgb(88, 88, 235), rgb(20, 145, 227));
  max-width: 100vw;
  height: 13vh;
  position: sticky;
  top: 0;
}

.bzco_logo {
  width: 18vw;
  min-width: 260px;
  mix-blend-mode: multiply;
}
.navContainer {
  width: 35vw;
  margin-left: 20vw;
}
.navs {
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  font-family: "Noto Serif Telugu", serif;
  width: 40vw;
}
.navs a {
  color: black;
  text-decoration: none;
  letter-spacing: 0px;
  padding: 12px;
  border-radius: 10px;
}
.active {
  background-color: rgba(44, 30, 233, 0.13);
  font-weight: bold;
}
.navs a:hover {
  color: white;
}

@media (max-width: 768px) {
  /* Your CSS rules for small screens here */
  .header {
    padding: 15px;
    height: fit-content;
    flex-direction: column;
  }

  .bzco_logo {
    width: 10vw;
  }
  .navContainer {
    align-self: flex-start;
    max-width: 100vw;
    margin-left: 0;
  }
  .navs {
    font-size: 1rem;
    width: 90vw;
    justify-content: center;
  }
}
