.landing-page {
  display: flex;
  flex-direction: column;
}
.sloganContainer {
  display: flex;
  max-width: 100vw;
  height: 35vh;
  justify-content: space-around;
  align-items: center;
}

.gallery {
  width: 34vw;
  align-self: center;
}
.gallery img {
  object-fit: contain;
  width: 17vw;
  max-width: 320px;
  border-radius: 5px;
}
.glassCleaning {
  float: right;
}
.mirrorCleaning {
  margin-top: 21px;
}
.sna {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 100vw;
  margin-top: 100px;
  align-items: center;
}

.slogan,
.snaH1 {
  color: #1b61bf;
  font-family: "Mooli", sans-serif;
}
.sloganDescription,
.snaP {
  font-size: 1.7rem;
  width: 40vw;
  font-family: "Mooli", sans-serif;
  text-align: center;
}
.snaLogo {
  width: 30vw;
  float: right;
}
.snaP {
  margin-top: 70px;
}

@media (max-width: 768px) {
  .sloganContainer {
    height: 30vh;
    padding: 10px;
  }
  .slogan,
  .sloganDescription,
  .snaP {
    font-size: 0.9rem;
  }
  .sloganDescription {
    width: 45vw;
  }

  .gallery {
    width: 65vw;
    margin-top: 10px;
  }
  .gallery img {
    width: 30vw;
  }
  .mirrorCleaning {
    margin-top: 6px;
  }

  .sna {
    margin-top: 50px;
  }
  .snaH1 {
    font-size: 1.5rem;
  }
  .snaLogo {
    width: 80vw;
  }
  .snaP {
    margin-top: 40px;
    width: 70vw;
  }
}
