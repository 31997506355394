.footer {
  max-width: 100vw;
  height: 40vh;
  margin-top: 80px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(rgb(66, 161, 223), rgb(27, 57, 209));
}
.footerH4 {
  font-size: 1.7rem;
  font-family: "Mooli", sans-serif;
}
.footerInfo {
  display: flex;
  width: 99vw;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.footerContact {
  font-size: 1.3rem;
  list-style-type: none;
  margin-top: 30px;
}
@media (max-width: 768px) {
  .footer {
    padding: 10px;
    height: 45vh;
  }
  .footerH4 {
    font-size: 1.2rem;
    width: 90vw;
  }
  .footerContact {
    margin-top: 0px;
    font-size: 1.1rem;
    justify-content: center;
    text-align: center;
  }
}
