.contact-page {
  display: flex;
  flex-direction: column;
}
.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  align-self: center;
}
.customerSupport {
  width: 28vw;
}
.contacteerOns {
  font-family: "Mooli", sans-serif;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: left;
  text-align: center;
}
