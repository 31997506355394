.diensten {
  display: flex;
  flex-direction: column;
}
.onzeDiensten {
  display: flex;
  align-self: center;
  margin-top: 100px;
  font-family: "Mooli", sans-serif;
  font-size: 3rem;
}
.location {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin: 100px;
  max-width: 100vw;
}
.locationH1 {
  color: #1b61bf;
  font-family: "Mooli", sans-serif;
  margin-bottom: 20px;
}
.locationP {
  font-size: 1.2rem;
  width: 60vw;
  font-family: "Mooli", sans-serif;
  text-align: center;
}

.services {
  display: flex;
  max-width: 100vw;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.service {
  width: 35vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}
.serviceName {
  font-family: "Mooli", sans-serif;
  font-size: 1.6rem;
}
.serviceImg {
  width: 5vw;
  margin: 35px;
}
.serviceBio {
  font-family: "Mooli", sans-serif;
  font-size: 1.2rem;
  text-align: center;
}
